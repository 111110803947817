<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
              >Do you want to delete this product ?</span
            >
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn tile block depressed dark color="red" @click="deleteProduct(dataItem)">
              <span style="font-family: poppinsregular; font-size: 14px">
                Yes
              </span>
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1BD93B"
              @click="deleteDialogue = false"
            >
              <span style="font-family: poppinsregular; font-size: 14px">
                No
              </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-2 pt-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Products
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                solo
                @keyup.enter.native="getData()"
                label="Search"
                single-line
                hide-details
              >
                <template v-slot:append>
                  <v-icon @click="getData()"> mdi-magnify </v-icon>
                </template>
              </v-text-field>
            </v-col> </v-card-title
          ><br />
          <v-layout wrap v-if="user.length > 0">
            <v-flex xs12 v-for="(item, i) in user" :key="i" pa-4>
              <v-layout wrap py-2>
                <v-flex xs12 sm12 md12 lg6>
                  <v-layout wrap justify-start>
                    <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
                      <v-img
                        :src="mediaUURL + item.photos[0]"
                        aspect-ratio="1"
                        contain
                        height="100px"
                      ></v-img>
                    </v-flex>
                    <v-flex xs12 sm9 md9 lg9 xl9>
                      <v-layout wrap justify-center fill-height>
                        <v-flex xs12>
                          <v-layout wrap justify-start>
                            <v-flex lg12 text-left>
                              <span
                                style="
                                  font-family: opensanslight;
                                  font-size: 16px;
                                  color: #000000;
                                "
                              >
                                {{ item.productname }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 align-self-end>
                          <v-layout
                            wrap
                            justify-start
                            v-for="(list, l) in item.size"
                            :key="l"
                          >
                            <v-flex xs6 sm3 md3 lg3 xl3>
                              <v-layout wrap v-if="list.size">
                                <v-flex text-left xs12>
                                  <span
                                    style="
                                      color: #8d8d8d;
                                      font-family: opensanslight;
                                      font-size: 14px;
                                    "
                                  >
                                    Variant
                                  </span>
                                </v-flex>
                                <v-flex text-left xs12>
                                  <span
                                    style="
                                      font-family: opensansbold;
                                      font-size: 20px;
                                    "
                                  >
                                    {{ list.size }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6 sm2 md2 lg2 xl2>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span
                                    style="
                                      color: #8d8d8d;
                                      font-family: opensanslight;
                                      font-size: 14px;
                                    "
                                    >Price</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span
                                    style="
                                      font-family: opensansbold;
                                      color: #3bc17a;
                                      font-size: 20px;
                                    "
                                    >{{ list.price }}</span
                                  >
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6 sm2 md2 lg2 xl2>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span
                                    style="
                                      color: #8d8d8d;
                                      font-family: opensanslight;
                                      font-size: 14px;
                                    "
                                    >Offer Price</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span
                                    style="
                                      font-family: opensansbold;
                                      color: #3bc17a;
                                      font-size: 20px;
                                    "
                                    >{{ list.offerPrice }}</span
                                  >
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex
                              xs6
                              sm3
                              md3
                              lg3
                              xl3
                              v-for="(sk, s) in list.stock"
                              :key="s"
                            >
                              <v-layout wrap>
                                <v-flex xs6>
                                  <v-layout wrap>
                                    <v-flex text-left lg12>
                                      <span
                                        style="
                                          color: #8d8d8d;
                                          font-family: opensanslight;
                                          font-size: 14px;
                                        "
                                        >Stock</span
                                      >
                                    </v-flex>
                                  </v-layout>
                                  <v-layout wrap>
                                    <v-flex text-left lg12>
                                      <span
                                        style="
                                          font-family: opensansbold;
                                          color: black;
                                          font-size: 20px;
                                        "
                                        >{{ sk.stock }}</span
                                      >
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs6 v-if="sk.color">
                                  <v-layout wrap>
                                    <v-flex text-left lg12>
                                      <span
                                        style="
                                          color: #8d8d8d;
                                          font-family: opensanslight;
                                          font-size: 14px;
                                        "
                                        >Colour</span
                                      >
                                    </v-flex>
                                  </v-layout>
                                  <v-layout wrap>
                                    <v-flex text-left lg12>
                                      <v-card
                                        outlined
                                        rounded="30px"
                                        class="colorselector"
                                        width="30px"
                                        :color="sk.color ? '#ffebeb' : ''"
                                      >
                                        <v-layout
                                          py-2
                                          wrap
                                          align-content-center
                                        >
                                          <v-flex xs12 text-center>
                                            <v-avatar
                                              :color="sk.color"
                                              size="15"
                                            />
                                          </v-flex>
                                        </v-layout>
                                      </v-card>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6 sm2 md2 lg2 xl2 text-right>
                              <v-switch
                                :disabled="
                                  list.status == 'Pending' ? true : false
                                "
                                v-model="list.status"
                                true-value="Active"
                                false-value="Inactive"
                                color="#5DC57C"
                                hide-details
                                :ripple="false"
                                class="statusKey"
                                :loading="statusLoading"
                                @click="changeStockStatus(list)"
                              >
                                <template slot="placeholder">
                                  <span
                                    style="
                                      color: #000000;
                                      font-family: opensanssemibold;
                                      font-size: 16px;
                                    "
                                  >
                                    {{ list.status }}
                                  </span>
                                </template>
                              </v-switch>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex text-left xs1 text-center hidden-md-and-down>
                      <v-divider vertical></v-divider>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex text-left xs12 md12 lg6>
                  <v-layout wrap justify-space-between fill-height>
                    <v-flex xs12 sm3 md3 lg3 xl3>
                      <v-layout wrap>
                        <v-flex xs6 sm12>
                          <span
                            style="
                              color: #8d8d8d;
                              font-family: opensanslight;
                              font-size: 14px;
                            "
                          >
                            Item added on
                          </span>
                        </v-flex>
                        <v-flex xs6 sm12>
                          <span
                            style="
                              font-family: opensanssemibold;
                              font-size: 15px;
                              color: #000000;
                            "
                          >
                            {{ formatDate(item.created_at).slice(0, 11) }}
                            <br />
                            {{ formatDate(item.created_at).slice(11, 20) }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 xl3>
                      <v-layout wrap>
                        <v-flex xs6 sm12>
                          <span
                            style="
                              color: #8d8d8d;
                              font-family: opensanslight;
                              font-size: 14px;
                            "
                          >
                            Available Stock
                          </span>
                        </v-flex>
                        <v-flex xs6 sm12 text-left align-self-start>
                          <span
                            style="font-family: opensansbold; font-size: 20px"
                          >
                            {{ item.stockquantity }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm2 md2 lg2 xl2>
                      <v-layout wrap fill-height>
                        <v-flex xs6 sm12 align-self-center v-if="item.hsnCode">
                          <span
                            style="
                              color: #8d8d8d;
                              font-family: opensanslight;
                              font-size: 14px;
                            "
                          >
                            HSN Code
                          </span>
                        </v-flex>
                        <v-flex
                          xs6
                          sm12
                          text-left
                          align-self-start
                          v-if="item.hsnCode"
                        >
                          <span
                            style="font-family: opensansbold; font-size: 20px"
                          >
                            {{ item.hsnCode }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 xl3 text-left pr-2>
                      <v-layout wrap justify-end fill-height>
                        <v-flex xs6 text-right>
                          <v-switch
                            :disabled="item.status == 'Pending' ? true : false"
                            v-model="item.status"
                            true-value="Active"
                            false-value="Inactive"
                            color="#5DC57C"
                            hide-details
                            :ripple="false"
                            class="statusKey"
                            :loading="statusLoading"
                            @click="changeProductStatus(item)"
                          >
                            <template slot="placeholder">
                              <span
                                style="
                                  color: #000000;
                                  font-family: opensanssemibold;
                                  font-size: 16px;
                                "
                              >
                                {{ item.status }}
                              </span>
                            </template>
                          </v-switch>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 text-right>
                      <v-layout wrap justify-end>
                        <v-flex xs12 xl12 align-self-end>
                          <v-layout wrap justify-end>
                            <v-flex xs5 sm5 md5 lg3 xl3>
                              <v-btn
                                tile
                                block
                                small
                                outlined
                                color="warning"
                                style="text-transform: none"
                                :to="'/productEdit?id=' + item._id"
                              >
                                <span
                                  style="
                                    color: #000;
                                    font-family: poppinsregular;
                                    font-size: 14px;
                                  "
                                >
                                  <v-icon left>mdi-pencil</v-icon>
                                  Edit
                                </span>
                              </v-btn>
                            </v-flex>
                            <v-flex xs1>
                              <v-icon @click="deleteDialogue = true, dataItem=item"
                                >mdi-delete</v-icon
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pb-5 v-else>
            <v-flex xs12 sm12 align-self-center pt-5>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 25px;
                  color: #000000;
                  cursor: pointer;
                "
                >Oops! No Products Found</span
              >
            </v-flex>
          </v-layout>
          <div class="pt-2" v-if="pages > 1">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      showSnackBar: false,
      statusLoading: false,
      deleteDialogue: false,
      search: "",
      widthOfCard: "250px",
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 16,
      ServerError: false,
      totalData: 0,
      totalRows: 0,
      user: [],
      dataItem:"",
    };
  },
  mounted() {
    if (this.$store.state.currentPage1) {
      this.currentPage = this.$store.state.currentPage1;
      this.getData();
    } else this.getData();
  },
  watch: {
    currentPage() {
      this.$store.commit("changePendCurrentPage", this.currentPage);

      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/seller/productList",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          keyword: this.search,
          sellerId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteProduct(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/admin/product/delete",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.getData()
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    changeProductStatus(item) {
      this.statusLoading = true;
      axios({
        method: "POST",
        url: "/admin/product/statusChange",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          status: item.status,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Product Status Changed to " + item.status;
            this.showSnackBar = true;
           this.getData()
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changeStockStatus(item) {
      this.statusLoading = true;
      axios({
        method: "POST",
        url: "/admin/productStock/statusChange",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          status: item.status,
        },
      })
        .then((response) => {
          this.statusLoading = false;
          if (response.data.status) {
            this.msg = "Product Status Changed to " + item.status;
            this.showSnackBar = true;
            this.getData()
          }
        })
        .catch((err) => {
          this.statusLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>